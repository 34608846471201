
#footer{
    position: relative;
    height: 100vh;
    bottom: 0px;
    
}
.footerContainer{
    width: 100%;
    height: 55%;
    position: absolute;
    z-index: 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    bottom: 0;
    border-radius: 0px 0px 0px 0px;
}
#bcg{
    background-image: url(../assets/clouds.png);
    background-size: cover;
    animation: cloudMovement 50s linear infinite alternate; 
}
@keyframes cloudMovement{
    0%{
        background-position: 0vw;
    }
    100%{
        background-position: 100vw ;
    }
}


@media only screen and (max-width: 600px){
    #footer{
        height: 120vh;
        text-align: center;
    }
    .footerContainer{
        border-radius:20px 20px 0px 0px;
    }
}

/* Define the wavy effect */
.wave {
    position: absolute;
    bottom: 52%; /* Position it just above the border of the footer */
    left: 0;
    width: 100%;
    height: 120px; /* Adjust height as needed */
    background: url('../assets/wave.png') repeat-x; /* Replace 'wave.png' with your wave image */
    background-size: 65rem ; /* Adjust background size as needed */
    animation: animate 15s linear infinite;
    z-index: 1; /* Ensure it has a higher z-index than other elements */
}

/* Apply opacity and animation delay to each wave */
.wave:nth-child(1) {
    opacity: 10;
}

.wave:nth-child(2) {
    opacity: 0.5;
    animation-delay: 0.2s;
    animation-direction: reverse;
}

.wave:nth-child(3) {
    opacity: 0.7;
    animation-delay: 5s;
}


  
  @keyframes animate {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: -250rem;
    }
  }
  