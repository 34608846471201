.card {
    background-color: #f1f1f1;
    color: #000000;
}

#bgrVideo {
    z-index: -50;
}

.whyUsSection {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}

.whatHappensSection {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}

#newsContainer {
    display: flex;
    justify-content: space-evenly;
    padding: 0rem 3rem 3rem 3rem;
}

#section {
    display: flex;
    flex-direction: column;
    background-color: #B4DBD9;
    border-radius: 20px;
    padding: 2rem;
    height: 70vh;
    align-items: center;
    overflow-y: scroll;
}

#section::-webkit-scrollbar {
    display: none;
}

.Divider {
    background-color: #7d8b8a;
    width: 0.2rem;
    height: 70vh;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 2em;
}

#contentContainer {
    display: flex;
    margin-top: 1rem;
    font-family: Maven Pro;
}

#sectionHeading {
    font-family: "Archivo", sans-serif;
    font: bold;
    color: #717070;
    font-size: 2rem;
    text-align: center;
}

.newsSectionContainer {
    height: 100vh;
}

@media (max-width: 800px) {
    .whyUsSection {
        height: 35vh;
    }
    .text-\[7rem\] {
        font-size: 3rem;
    }
    #section {
        flex-direction: column;
        height: 25vh;
        margin: 0%;
        padding: 2%;
        border-color: #B4DBD9;
        border-width: 1em;
        margin-bottom: 3em;
    }
    .Divider {
        display: none;
    }
    #newsContainer {
        display: flex;
        flex-direction: column;
        height: 90vh;
    }
    #newsSectionContainer {
        padding: 0%;
        border-width: 1em;
    }
    .headi {
        margin: 0;
    }
    #sectionHeading {
        position: relative;
        padding: 18%;
        padding-top: 0;
        padding-bottom: 0;
        top: 10px;
    }
}




.MissionVisionContainer{
    height: 100vh; 

    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 3fr;
}
.MissionVisionContainer .headingContainer{
    grid-row: 1/3;

    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;

    margin-top: 4rem;
}
.MissionVisionContainer .headingContainer h1{
    font-family: Archivo,sans-serif;
    color: #39AEA8;
    font-size: 3.5rem;
    
    display: flex;
    justify-content: center;

    position: absolute;
    top: 10vh;

    word-wrap: normal;
    word-break: keep-all;

}
.MissionVisionContainer .headingContainer p{
    margin: 0rem 6.9rem;
}

.MissionVisionContainer .headingContainer img{
    position: absolute;
    top:20vh;
}
.MissionVisionContainer .listContainer{
    grid-column: 2/3;
    grid-row: 1/3;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.MissionVisionContainer .listContainer .listObj{
    margin: 0.5rem 4rem;

    display: grid;
    grid-template-columns: 1fr 9fr;
}
.MissionVisionContainer .listContainer .listObjNum{
    font-family: Archivo,sans-serif;
    font-size: 4rem;
    color: #39AEA8;

    display: flex;
    justify-content: center;
    align-items: center;
}
.MissionVisionContainer .listContainer .listObjBody{
    display: flex;
    align-items: center;
}
@media (max-width: 800px) {
    .MissionVisionContainer{
        height: 120vh;
        display: flex;
        flex-direction: column;
    }
    .MissionVisionContainer .headingContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    
        margin-top: 1rem;
    }
    .MissionVisionContainer .headingContainer h1{
        font-family: Archivo,sans-serif;
        color: #39AEA8;
        font-size: 3rem;
        line-height: 3rem;

        display: flex;
        justify-content: center;
        margin: 1rem 2rem 1rem 2rem;
    
        word-wrap: normal;
        word-break: keep-all;
    
    }
    .MissionVisionContainer .headingContainer p{
        margin: 0rem 2rem;
        /* font-size: 0.8rem; */
    }
    .MissionVisionContainer .imgContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .MissionVisionContainer .imgContainer img{
        margin-top: 1rem;
        width: 40%;
        display: none;
    }
    .MissionVisionContainer .listContainer{
        margin-top: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .MissionVisionContainer .listContainer .listObj{
        margin: 0.2rem 2rem;
    
        display: grid;
        grid-template-columns: 1fr 9fr;
    }
    .MissionVisionContainer .listContainer .listObjNum{
        font-family: Archivo,sans-serif;
        font-size: 2.5rem;
        color: #39AEA8;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .MissionVisionContainer .listContainer .listObjBody{
        display: flex;
        align-items: center;
    }
}
