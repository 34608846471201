#topSection {
    background: #39AEA8;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-direction: forwards;
}



#heading {
    color: #f1f1f1;
    position: relative;
    bottom: -4.5vw;
}


#Content {
    width: 100vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    padding: 4%;
    align-items: center;
}


.Card {
    background: #ffffff;
    height: 20vw;
    width: 20vw;
    border-radius: 2rem;
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

@media (max-width: 600px) {
    #Content {
        display: flex;
        flex-direction: column;
        font-size: x-small;
        padding: 0%;
        overflow-y: visible;
        height: 65vh;
        margin: 0%;
    }
    
    .Card {
        width: 90vw;
        height: 15vh;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding-bottom: 10%;
        border-radius: 1rem;
    }
    #heading{
        bottom: -9vw;
        font-size:3rem;
    }

}