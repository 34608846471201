#bottomCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: +30;
    margin-top: 100vh;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
    border-radius: 180px 0px 0px 0px;
    height: 55vh;
    width: 62%;
    animation-duration: 1s;
    animation-name: diagPopBtm;
    animation-fill-mode: forwards;
    overflow: hidden;
}

#cardHead {
    /* opacity: 0%; */
    transition: all ease-in 0.5s;
    font-size: 4.5vw;
    line-height: 100%;
    animation-duration: 2s;
    animation-name: fade-in;
    animation-fill-mode: forwards;
}

#cardDesc {
    /* opacity: 0%; */
    margin-top: 2%;
    font-size: 1rem;
    animation-duration: 2.5s;
    animation-name: fade-in;
    animation-fill-mode: forwards;
}

#cardCTA {
    /* opacity: 0%; */
    display: flex;
    align-items: center;
    margin-top: 2%;
    animation-duration: 3s;
    animation-name: fade-in;
    animation-fill-mode: forwards;
}

#cardBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vw;
    width: 4vw;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: #3AA29D;
    background-image: linear-gradient(-45deg, #FFFDFD, #dedede);
    box-shadow: -9px -5px 43px 3px #FEFEFE, 5px 6px 33px 3px #DAD6D6;
}

#cardBtn:active {
    box-shadow: inset -9px -5px 43px 3px #FEFEFE, inset 5px 6px 33px 3px #DAD6D6;
}

#carBtn:focus {
    outline: none;
}

@keyframes diagPopBtm {
    0% {
        width: 0%;
        height: 0%;
        margin-top: 100vh;
    }
    100% {
        width: 62%;
        height: 55vh;
        margin-top: 45vh;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0%;
    }
    50% {
        opacity: 0%;
    }
    100% {
        opacity: 100%
    }
}

@media (max-width: 800px) {
    #bottomCard {
        border-radius: 90px 0px 0px 0px;
    }
    #cardHead {
        font-size: 5vw;
    }
}