#topSection {
    background: #39AEA8;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-direction: forwards;
}

.studentBody {
    flex-direction: column;
    padding: 5%;
    display: flex;
    width: 100%;  
    align-items: center
}

.scrollContainer {
    margin-right: 0rem;
    margin-top: 0rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
}

.yearButton {
    background-color: #f1f1f1;
    box-shadow: 4px 5px 13px #CECECE, -6px -4px 7px #ffffff;
    min-width: 10rem;
    height: 2rem;
    border-radius: 8px;
    margin: 1rem 1rem;
    font-family: Archivo,sans-serif;
    color: #39AEA8;
}

.yearButton:hover {
    background-color: #39AEA8;
    color: #f1f1f1;
}

.yearButton:active {
    box-shadow: inset 8px 10px 13px #21736f, inset -12px -8px 7px #39b0ab;
}

.yearButton .Selected {
    background-color: #39AEA8;
    box-shadow: 4px 5px 13px #CECECE, -6px -4px 7px #ffffff;
    min-width: 10rem;
    height: 2rem;
    border-radius: 8px;
    margin: 1rem 1rem;
    font-family: Archivo,sans-serif;
    color: #f1f1f1;
}

.studentsContainer {
    box-shadow: 4px 5px 13px #CECECE, -6px -4px 7px #ffffff;
    padding: 1rem;
    border-radius: 2rem;
    width: 70%;
    height: 40vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.studentsContainer::-webkit-scrollbar {
    display: none;
}

#studentInfo {
    display: flex;
    margin-right: 3rem;
    flex-direction: column;
}

@media (max-width: 600px) {
    #heading {
        padding: 10%;
    }
    .studentBody {
        flex-direction: column;
        padding: 5%;
    }
    .studentsContainer {
        width: 100%;
        padding-left: 1%;
        margin-left: 3%;
        height: 40vh;
        overflow-y: scroll;
    }
    .scrollContainer{
        justify-content: start;
    }

}
.myBubbleUI {
	width: 100%;
	max-width: 90vw;
    margin-top:2rem;
	height: 70vh;
	border-radius: 50px;
    border-radius: 2rem;
    box-shadow: 4px 5px 13px #CECECE, -6px -4px 7px #ffffff;
    
}
