.facultyCard {
    background-color: #f1f1f1;
    width: 20vw;
    height: 22vw;
    box-shadow: 20px 20px 15px 1px #d2d2d36c, -10px -10px 40px 1px #ffffff;
    border-radius: 80px;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

#topSectionFaculty {
    position: relative;
    background: #39AEA8;
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-end;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-direction: forwards;
}

#headingFaculty {
    color: #f1f1f1;
    bottom: -4.5vw;
    position: relative;
}
#ContentFaculty {
    height: 60vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4%;
}
.facultyContainer {
    border-radius: 2rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

#headingSection {
    background-color: #39AEA8;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100%;
}

#profCardContainer {
    height: 100%;
    width: 50%;
    padding-left: 2rem;
    margin-top: 13%;
    overflow-y: scroll;
}

#profCardContainer::-webkit-scrollbar {
    display: none;
}

@keyframes autoScroll {
    0% {
        top: 0;
    }
    100% {
        top: -98vw;
    }
}

@keyframes autoScroll2 {
    0% {
        bottom: 100vw;
    }
    100% {
        bottom: 0;
    }
}

.facultyImage {

    width: 60%;
    animation: moveIn 1s forwards;
}

@keyframes moveIn {
    0% {
        bottom: -5rem;
    }
    100% {
        bottom: 0;
    }
}
@keyframes moveInSm {
    0% {
        left: 5rem;
    }
    100% {
        left: 0;
    }
}

#staffCardContainer {
    padding-left: 2rem;
    padding-top: 15vh;
    align-items: center;
    display: flex;
    width: 100%;
    overflow-y: scroll;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#staffCardContainer::-webkit-scrollbar {
    display: none;
}
.buttonsss{
    display:flex ;
    flex-direction:column;
    height:6rem;

}
.advisory {
    border-radius: 2rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    align-items: center;

}
.PhdContainer{
        align-self: start;

}

@media (max-width: 800px) {

    .facultyContainer{
        align-items:center;
        flex-direction:column;
        flex-wrap: nowrap;
    }

    #ContentFaculty{
        flex-direction: column;
        align-items: center;
    }
    .buttonsss{
        flex-direction: column;
        align-items: center;
        height: 12rem;
        width: 90%;

    }
    .hereis2 {
        
        height: 35vh !important;
        
    }
    #headingSection {
        bottom: -9vw;
        font-size: 3rem;
        position: absolute;
        left: 50%;
        top: 90%;
        transform: translateX(-50%);
    }
    .datai {
        position: fixed;
        top: 20em;
        width: 100%;
    }
    #profCardContainer {
        overflow-y: scroll;
        margin: 0%;
        padding: 0% 1% 0% 4%;
        background-color: transparent;
    }
    .facultyCard {
        width: 80%;
        height: 80%;
        border-radius: 10%;
        padding: 10%;
    }
    .facultyImage {
        width: auto;
        animation: moveInSm 0.5s ease-in forwards;
    }
    .imi {
        width: 60%;
        height: 100%;
    }
    .texti {
        font-size: x-small;
    }
    #staffCardContainer {
        overflow-y: scroll;
        margin: 0%;
        padding: 0% 6% 60% 4%;
        height: 60vh;
        background-color: transparent;
        z-index: 10;
    }
    #headingFaculty{
        bottom: -9vw;
        font-size:3rem;
    }
    #we{
        height: 12rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
    }
    
}

