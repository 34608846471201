  .carousel-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 500px; /* Adjust height as needed */
    margin: 10px auto 0; /* Added margin at the top */
    position: relative;
  }

  .carousel-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Ensure the content is at the bottom */
    height: 100%;
    border: 5px solid #39AEA8; /* Border color */
    width: calc(100% - 80px);
    position: relative;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .carousel-content {
    height: 60px; /* Fixed height for the content */
    width: 100%;
    text-align: center;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: #39AEA8; /* Text color */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the text vertically */
    position: absolute;
  }
  
  .carousel-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
  
  .carousel-details {
    font-size: 1rem;
    margin: 0;
  }
  
  .carousel-prev {
    left: auto; /* Positioned at the left */
  }
  
  .carousel-next {
    right: auto /* Positioned at the right */
  }
  