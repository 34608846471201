*{
    transition: all ease-in-out 0.2s;
}
.section1Container{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.sec1Info{
    margin-top: 17rem;
    margin-left: 4rem;
    grid-column: 1/2;
    margin-right: 7rem;
}
.sec1Info h1{
    font-family: Archivo,sans-serif;
    font-size: 2.3rem;
}
.sec1Info h2{
    font-family: Archivo,sans-serif;
    font-size: 4rem;
    line-height: 4rem;

    color: #39AEA8;
}
.sec1Info p{
    width: 85%;
    margin-top: 2rem;
    font-family: Archivo,sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 1rem;
    line-height: 2rem;
}
.sec1ImgContainer{
    
    overflow: hidden;
    display: flex;
    grid-column: 2/3;
    height: 100vh;
    justify-content: end;
}
.sec1Img{
    height: 100%;
    z-index: -20;
    opacity: 100%;
    animation-duration: 1s;
    animation-name: moveSideIn;
    animation-fill-mode: forwards;
}
@keyframes moveSideIn{
    0%{
        transform: translateX(10vw);
    }
    100%{
        transform: translateX(0);
    }
}
@keyframes fadeIn {
    0%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
    }
}
@media (max-width: 800px) {
    .section1Container{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        background-color: #39AEA8;
        padding-bottom: 3rem;
        color: #f1f1f1

    }
    .sec1Info{
        margin-top: 1rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .sec1Info h1{
        font-family: Archivo,sans-serif;
        font-size: 2rem;
    }
    .sec1Info h2{
        font-family: Archivo,sans-serif;
        font-size: 3rem;
        line-height: 3rem;
        color: #f1f1f1;
    }
    .sec1Info p{
        width: 100%;
        margin-top: 2rem;
        font-family: Archivo,sans-serif;
        font-style: italic;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .sec1ImgContainer{
        height: 70%;
    }
    .sec1Img{
        z-index: 0;
        height:80%;
        margin-top: 20%;
    }
}




.section2Container{
    width: 100%;
    height: 100vh;
    
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden
}
.section2Container img{
    z-index: -5;
    height: 80%;
}
.section2Container .textContainer{
    font-family: Archivo,sans-serif;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
}
.textContainer .flex1{
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: space-evenly;

    margin-top: 10rem;
}
.textContainer .flex2{
    width: 100%;
    display: flex;
    justify-content: center;

    margin-top: 6rem;
}
.textContainer .left{
    width: 34%;
    text-align: right;
    margin-right: 8rem;
    line-height: 2rem;
}
.textContainer .right{
    width: 34%;
    text-align: left;
    margin-left: 8rem;
    line-height: 2rem;
}
.textContainer .bottom{
    width: 30%;
    text-align: center;
    
    line-height: 2rem;
}
@media (max-width: 800px) {

    .textContainer{
        height: 70%;
    }
    .textContainer .flex1{
        display: flex;
        flex-direction: column;
        align-items: center;
        height:auto;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 3rem 0rem 0rem 0rem;
    }
    .textContainer .flex2{
        width: 100%;
        display: flex;
        justify-content: center;
    
        margin-top: 12rem;
    }
    .textContainer .left{
        width: 90%;
        text-align: left;
        margin-right: 0rem;
        margin-top: 1rem;
        line-height: 1.5rem;
    }
    .textContainer .right{
        width: 90%;
        text-align: left;
        margin-top: 1rem;
        margin-left: 0rem;
        line-height: 1.5rem;
    }
    .textContainer .bottom{
        width: 90%;
        margin-top: 1rem;
        text-align: left;
        line-height: 1.5rem;
    }
}

.section3Container{
    height: 100vh;
    display: grid;
    grid-template-columns: 4fr 7fr;
}

.section3Container img{
    height: 100vh;
    object-fit: cover;
}
.section3Container .infoContainer{
    margin-top: 10rem;
    font-family: Archivo,sans-serif;
    margin: 12rem 9rem 0rem 9rem;
}
.section3Container .infoContainer h1{
    text-align: left;
    color: #39AEA8;
    font-size: 6rem;
    font-weight: 600;
}
.section3Container .infoContainer p{
    font-size: 1rem;
    line-height: 2rem;

}
@media (max-width: 800px) {
    .section3Container img{
        height: 100%
    }
    .section3Container{
        display: flex;
        flex-direction: column;
        background-color: #39AEA8;
        color: #f1f1f1;
        justify-content: center;
    }
    .section3Container .infoContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height:auto;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 3rem 0rem 0rem 0rem;
    }
    .section3Container .infoContainer h1{
        text-align: left;
        color: #f1f1f1;
        font-size: 3.5rem;
        font-weight: 600;
    }
}




.section4Container{
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 3fr;
}
.section4Container .headingContainer{
    grid-column: 1/2;
    grid-row: 1/2;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 4rem;
}
.section4Container .headingContainer h1{
    font-family: Archivo,sans-serif;
    color: #39AEA8;
    font-size: 3.5rem;
    
    display: flex;
    justify-content: center;

    word-wrap: normal;
    word-break: keep-all;

}
.section4Container .headingContainer p{
    margin: 0rem 6.9rem;
}
.section4Container .imgContainer{
    grid-column: 1/2;
    grid-row: 2/3;

    display: flex;
    justify-content: center;
    align-items: center;
}
.section4Container .imgContainer img{
    width: 70%;
}
.section4Container .listContainer{
    grid-column: 2/3;
    grid-row: 1/3;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section4Container .listContainer .listObj{
    margin: 0.5rem 4rem;

    display: grid;
    grid-template-columns: 1fr 9fr;
}
.section4Container .listContainer .listObjNum{
    font-family: Archivo,sans-serif;
    font-size: 4rem;
    color: #39AEA8;

    display: flex;
    justify-content: center;
    align-items: center;
}
.section4Container .listContainer .listObjBody{
    display: flex;
    align-items: center;
}
@media (max-width: 800px) {
    .section4Container{
        display: flex;
        flex-direction: column;
    }
    .section4Container .headingContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    
        margin-top: 1rem;
    }
    .section4Container .headingContainer h1{
        font-family: Archivo;
        color: #39AEA8;
        font-size: 3rem;
        line-height: 3rem;

        display: flex;
        justify-content: center;
        margin: 1rem 2rem 1rem 2rem;
    
        word-wrap: normal;
        word-break: keep-all;
    
    }
    .section4Container .headingContainer p{
        margin: 0rem 2rem;
        /* font-size: 0.8rem; */
    }
    .section4Container .imgContainer{
        justify-content: center;
        align-items: center;

    }
    .section4Container .imgContainer img{
        margin-top: 1rem;
        width: 40%;
        display: none;
    }
    .section4Container .listContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .section4Container .listContainer .listObj{
        margin: 0.2rem 2rem;
    
        display: grid;
        grid-template-columns: 1fr 9fr;
    }
    .section4Container .listContainer .listObjNum{
        font-family: Archivo;
        font-size: 2.5rem;
        color: #39AEA8;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .section4Container .listContainer .listObjBody{
        display: flex;
        align-items: center;
    }
}





.section5Container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section5Container .subjects{
    width: 80%;
    display: flex;
    justify-content: left;
    overflow: auto;

    margin-bottom: 4rem;
}
.section5Container .subjects .subject{
    background-color: #39AEA8;
    box-shadow: 4px 5px 13px #CECECE, -6px -4px 7px #ffffff;
    min-width: 10rem;
    height: 2rem;
    border-radius: 8px;
    margin: 1rem 1rem;

    font-family: Archivo;
    color: #ffffff;
}
.section5Container .subjects .subject:hover{
    background-color: #278b86;
}
.section5Container .subjects .subject:active{
    box-shadow: inset 8px 10px 13px #21736f, inset -12px -8px 7px #39b0ab;
}
.section5Container .subjects .selectedSubject{
    background-color: #f1f1f1;
    box-shadow: 4px 5px 13px #CECECE, -6px -4px 7px #ffffff;
    min-width: 10rem;
    height: 2rem;
    border-radius: 8px;
    margin: 1rem 1rem;

    font-family: Archivo;
    color: #414141;
}
.section5Container .subjects .selectedSubject:hover{
    background-color: #dfdcdc;
}
.section5Container .subjects .selectedSubject:active{
    box-shadow: inset 8px 10px 13px #CECECE, inset -12px -8px 7px #ffffff;

}

.section5Container .courses{
    width: 80%;
    min-height: 25rem;
    display: flex;
    justify-content: left;
    overflow: auto;
}
.section5Container .courses .course{
    min-width: 20rem;

    margin: 1rem 1rem;
    
}
.section5Container .courses .course h1{
    font-family: Archivo;
    font-size: 1.6rem;
    color: #39AEA8;
}
.section5Container .courses .course h2{
    font-family: Archivo;
    font-size: 1.2rem;
    color: #414141;
}
.section5Container .courses .course p{
    font-family: Maven Pro;
}


.b1{
    background-color: #39AEA8;
    box-shadow: 4px 5px 13px #CECECE, -6px -4px 7px #ffffff;
    min-width: 10rem;
    height: 2rem;
    border-radius: 8px;
    margin: 1rem 0rem ;    
    bottom: 0;

    

    font-family: Archivo;
    color: #ffffff;
}
.b1:hover{
    background-color: #278b86;
}

.collabContainer{
    margin-top:15rem;
    display: grid;
    grid-template-columns: repeat(5, 10fr);
    grid-template-rows: repeat(4, 8vw);
    gap: 30px;
   

}
@media screen and (max-width: 800px) {
    #collabContainer{
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    
}





.section6Container{
    height: 100vh;

    display: grid;
    grid-template-columns: 1fr 1fr;
}
.section6Container .infoContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0rem 3rem;
}

@media only screen and (max-width: 800px) {
    .section6Container{
        display: flex;
        height: auto;
        flex-flow: column-reverse;
    }
    .section6Container .infoContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        margin: 0rem 3rem;
    }
    .section6Container .imgContainer{
        height: 50vh;
        padding-top: 10rem;
    }

}

.section6Container .infoContainer h1{
    font-family: Archivo;
    font-size: 4rem;
    font-weight: 700;
    color: #39AEA8;
}
.section6Container .infoContainer p{
    display: flex;
    text-align: left;
}
.section6Container .infoContainer h4{
    margin-top: 1rem;
}
.section6Container .infoContainer a{
    text-decoration: none;
    color: #39AEA8;
}
.section6Container .imgContainer{
    background-color: #39AEA8;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-direction: forwards;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section6Container .imgContainer img{
    width: 80%;
    object-fit: cover;
}





.section7Container{
    min-height: calc(100vh - 20rem);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section7Container h1{
    font-family: Archivo;
    font-size: 3.5rem;
    color: #39AEA8;

    display: flex;
    justify-content: center;

    /* margin-bottom: 2rem; */
}

@media only screen and (max-width: 600px) {
    .section7Container h1{
        font-size: 2rem;
        text-align: center;
    }
}

.section7Container .cardsContainer{
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-direction: forwards;
    display: flex;
    justify-content: space-around;
    width: 90%;
    flex-flow: wrap;
    margin-bottom: 3rem;
}

.section7Container .cardsContainer button{
    height: 15rem;
    width: 15rem;
    background-color: #f1f1f1;
    border-radius: 25px;
    box-shadow: 8px 10px 26px #CECECE, -12px -8px 14px #ffffff;

    margin: 2rem 0.5rem 0rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section7Container .cardsContainer button:hover{
    background-color: #39aea813;
}
.section7Container .cardsContainer button:active{
    box-shadow: inset 8px 10px 26px #CECECE, inset -12px -8px 14px #ffffff;
}

.section7Container .cardsContainer button p{
    font-family: Maven Pro;
    margin: 0rem 1.5rem;
    z-index: 5;
}
.section7Container .cardsContainer button img{
    height: 6rem;
    position: absolute;
    z-index: 1;
    margin-top: 4.54rem;
    margin-left: 3rem;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background: #278b86;
    border-radius: 10px;
    width: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #1f7570;
}
