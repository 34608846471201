
.profCard{
    display: flex;
    position: relative;
    flex-direction: column;
    min-width: 12rem;
    height: 18rem;
    color: #414141;

    overflow: hidden;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 0.6rem;
    transition: transform ease-out 0.5s;
    /* transform: scale(1); */
    margin-right: 2rem;
}
.profCard-Selected{
    background-color: #39AEA8;
    color: #ffffff;
    /* transition: transform ease-out 0.2s; */
    /* padding: 0; */
    transform: scale(1.05);

}


.profCard:hover{
    transition: transform ease-out 0.2s;
    transform: scale(1.05);
}
.profImg{
    border-radius: 8px;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 80%;
}
.profImgSelected{
    /* object-fit: cover; */
    transition: height ease-out 0.5s;
    height: 100%;
}






.projectContainer{
    display: flex;
    justify-content: space-evenly;

    font-family: Maven Pro;
    margin: 1rem;
}

.projectContainer .projectDescription{
    text-align: justify;
    margin-right: 3rem;
}
.projectContainer .projectIndex{
    font-family: Archivo,sans-serif;
    font-weight: 700;
    font-size: 3rem;
    color: #39AEA8;
    margin-right: 1rem;
    line-height: 3rem;

    display: flex;
    align-items: top;
}
.phdContainer .projectDescription{
    font-family: Archivo,sans-serif;
    font-weight: 700;
    font-size: 3rem;
    color: #39AEA8;
    margin-right: 1rem;
    line-height: 3rem;

    display: flex;
    align-items: top;
}

@media screen and (max-width: 500px) {
    .projectContainer{
        display: block;
        
    }
}