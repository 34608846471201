.peopleSection {
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
}

.alumniSection {
    background-color: #096964;
}

.studentsSection {
    background-color: #239C9B;
}

.papersSection {
    background-color: #39AEA8;
}

.facultySection {
    background-color: #58B9B4;
}

.SectionHeading {
    display: flex;
    margin-right: 14%;
    writing-mode: vertical-rl;
}

@media (max-width: 800px) {
    .conti {
        z-index: 10;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    .imgi {
        width: 100%;
        height: 35vh;
        border-bottom-right-radius: 6em;
    }
    .SectionHeading {
        writing-mode: horizontal-tb;
        top: 10em;
        left:0;
        margin-left:1rem;
        padding: 5%;
        justify-content: flex-start;
    }
}