

.panelBody{
    display: flex;
    align-items: center;
    z-index: 10;
    overflow: hidden;
}
@media only screen and (max-width: 600px) {
    .panelBody{
        flex-direction: column;
        padding-top: 60%;
    }
    
}

#researchpanel{
    position: relative;
    height: 100vh;
    width: calc(100vw / 5); 
    
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    
    overflow: hidden;
    transition: transform 0.3s ease-out;
    transform: scale(1);
}

#researchpanel:hover{
    transition: transform 0.3s ease-out;
    transform: scale(1.025);
}


@media only screen and (max-width: 600px){
    #researchpanel{
        height: 40vh;
        width: 100vw;
        flex-direction: row;
        justify-content: space-between;
    }
}


#panelImage{
    position: relative;
    object-fit: cover;
    width: 100%;
    margin-top:10vw;
    animation-name: imgDown;
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
@media only screen and (max-width: 600px){
    #panelImage{
        width: 50%;
    }
}
#logoSec{
    position: absolute;
    z-index: 10;
    padding: 1vw;
    top:20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    animation-name: logoUp;
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
#logo{
    object-fit: cover;
    background-color: #EEEEEE;
    border-radius: 20em;
    padding: 0.5rem;
    width: 10vw;
    height: 10vw;
    box-shadow: 2px 2px 45px 1px #6161615b;
}
@media only screen and (max-width: 600px){
    #logoSec{
        right: 5vh;
        padding: 0;
        width:50%;
    }
    #logo{
        padding: 0.1rem;
        width: 15vh;
        height: 15vh;
    }
}


/* body  */

.body{
    display: flex;
    width: 100vw;
    height: 100vh;

    justify-content: flex-end;

    z-index: -20;
    position: absolute;
    top: 0px;
    overflow: hidden;

    animation-name: researchBodyBringFront;
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
@keyframes researchBodyBringFront {
    0%{
        z-index: -20;
    }
    100%{
        z-index: 40;
    }
}
@media only screen and (max-width:600px){
    .body{      
        top:40vh;
        width:100%;
        height:200%;
        justify-content:center;
    }
}



#body-section{
    transform: translateX(100%);
    transition: transform ease-out 0.5s;
    width: 75%;
    margin-right: calc(10vh - 2rem);
    text-align: right;
    overflow: hidden;
    animation-name: researchBodyFadeIn;
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
@keyframes researchBodyFadeIn {
    0%{
        opacity: 0%;
        transform: translateX(100%);
        z-index: -20;
    }
    100%{
        opacity: 100%;
        transform: translateX(0);
        z-index: 40;
    }
}
@media only screen and (max-width:600px){
    #body-section{      
        transform: none;
        margin-right: 0%;
        text-align: center;
        width: 80%;
        overflow-y: scroll;
    }
}


#title{
    margin-right: 2rem;
    right: 0;
    padding-left: 30%;
    font-size: 2.2rem;
    line-height: normal;

}
@media only screen and (max-width: 600px){
    #title{
        text-align: left;
        margin-right: 0;
        padding-left: 0;
        padding-right: 3rem;
    }
}


#info{
    margin-right: 2rem;
}
@media only screen and (max-width: 600px){
    #info{
        text-align:left;
        margin-right: 0;
        font-size: 1.8rem;
        margin-top: 3rem;
        line-height:normal;
        padding-right: 5rem;
    }
}


#contentSpace{
    display: flex;
    position: absolute;
    flex-direction: column;
    top:20%;
    margin-right: 2rem;
}
@media only screen and (max-width: 600px){
    #contentSpace{
        margin-right: 0;
        align-items:center;
    }
}


#description{
    align-self: flex-end;
    margin-top: 3em;
    margin-right: 2rem;
}
@media only screen and (max-width: 600px){
    #description{
        text-align: left;
        margin-top: 1rem;
        margin-right: 0;
        align-self: center;
        font-size:1.2rem;
        width: 100%;
    }
}



#btn{
    text-align: center;
    margin-top:2.5em;
    align-self: flex-end;
    border-radius: 1em;

    margin-right: 2rem;
}

@media only screen and (max-width: 600px){
    #btn{
        margin-right: 0;
        align-self: center;
        font-size: 1rem;
        width: 60%;
    }
}



#backBtn{
    margin: 1.5em;
    transform: scale(1.5);
    position: absolute;
    z-index: 20;
    opacity: 0%;

    animation-name: backButtonFadeIn;
    animation-delay: 1s;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}
@media only screen and (max-width:600px){
    #backBtn{
        top: 35vh;
    }
}



@keyframes logoUp {
    0%{
        transform: translateY(0vw)
    }
    100%{
        transform: translateY(-10vw)
    }
    
}
@keyframes imgDown {
    0%{
        transform: translateY(0vw)
    }
    100%{
        transform: translateY(10vw)
    }
    
}
@keyframes backButtonFadeIn {
    0%{
        opacity: 0%;
        z-index: 20;
    }
    100%{
        opacity: 100%;
        z-index: 50;
    }
}






.projBody{
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.researchPublicationsSection{
    /* position : relative; */
    top: -2em;
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    background-color: #ffffff;
    width: 94%;

    align-self: center;
    margin-top: 2em;
    margin-bottom: 2em;
}
.researchProfScroll{
    display: flex;
    overflow-x: scroll;
    justify-content: left;
    min-height: 20rem;
    min-width: 100%;
    padding: 3rem 3rem;
}

/* .profScroll::-webkit-scrollbar{
    display: none;
}
.projScroll::-webkit-scrollbar{
    display: none;
} */

#topSection{
    background: #39AEA8;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-direction: forwards;
}
#heading {
    color: #f1f1f1;
    position: relative;
    bottom: -4.5vw;
}
.pubDOI{
    text-decoration: none;
    color: #39AEA8;
    word-wrap: break-word;
}

@media only screen and (max-width: 600px){
    #heading{
        bottom: -9vw;
        font-size:3rem;
        text-align:center;
    }
    .profScroll::-webkit-scrollbar{
        display: flex;
    }
    .profScroll{
        margin-bottom: 3rem;
        min-height: auto;
        padding: 1rem 1rem;
    }
}